<template>
  <div class="">
    <div class="top">
      <div class="logo">
        <img src="../assets/img/logo.png" alt="" class="icosn" />
      </div>
      <div class="right">
      <div class="navbtn" @click="ethConnect">
        <img src="../assets/img/trx.png" alt="" class="txicon" />
        <span class="lang">
          {{ userWallet | yingcang }} <van-loading v-if="ljloading" />
        </span>
      </div>
      <div class="menu">
        <img src="../assets/img/menu.png" alt="" class="ico" @click="menuopen" />
      </div>
    </div>
    </div>
       <!-- 菜单 -->
       <van-popup
      v-model="menuShows"
      position="right"
      :style="{ height: '100%',background:'#000000'}"
    >
    <div class="sfelx" >
      <img src="../assets/img/gb.png" alt="" class="gb" @click="gbsmenu"/>
      <div class="mentop">       
        <img src="../assets/img/trx.png" alt="" class="logo" />
        <div class="address">{{userWallet|yingcang}}</div>
        <div class="level">
          <!-- <img src="../assets/img/lv1.png" alt="" class="ico" /> -->
          {{this.$store.getters.intID}}
        </div>        
      </div>
      <div class="menulist">
        <div class="menuitem fadeInLeft" :class="active=='/'?'act':''" @click="toPage('/')">首页</div>         
        <div class="menuitem fadeInLeft" :class="active=='/Team'?'act':''"  @click="toPage('/Team')" >团队</div>
        <div class="menuitem fadeInLeft" :class="active=='/Pool'?'act':''" @click="toPage('/Pool')">矿场</div>
        <div class="menuitem fadeInLeft" :class="active=='/My'?'act':''" @click="toPage('/My')">我的</div>
      </div>      
      <div class="foot">
        <div class="link">
          <img src="../assets/img/ico1.png" alt="" class="limg"  @click="to('https://t.me/vulcanusdt')">
          <!-- <img src="../assets/img/ico2.png" alt="" class="limg"> -->
          <!-- <img src="../assets/img/ico3.png" alt="" class="limg"> -->
        </div>
        <div class="price">Copyright © 2024 项目名称. All rights reserved. </div>
      </div>
    </div>
    </van-popup>
    
  </div>
  </template>
  
  <script>
  import { identity} from "@/api/Public";
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    computed: {
      dqyy() {
        if (this.$store.getters.language) {
          return this.$store.getters.language;
        } else {
          return "en-US";
        }
      },
    },
    data(){
      return{
        userWallet:'',
        clmenuShows:false,
        menuShows:false,
        active:"/",
        ljloading:false,
        lang:{},
        langs:[
        ]
      }
    },
    filters:{
      yingcang(val) {
        if (val) {
          let a = val.replace(/(\w{7})\w+(\w{4})/, "$1...$2");       
          return a;
        } else {
          return "连接钱包";
        }
      },
    },
    created(){
      this.ethConnect()
    },
    methods:{
      ethConnect() {
        identity().then((info) => {
          if (info.indexOf("T") == 0) {
            this.userWallet=info  
            this.$store.commit("userWallet", info); 
                    // this.shows=false                  
      // transferHt(info[0]).then(res=>{
      //   if(res){
          // this.pustadd(info[0])
        // }
        // })
          } else {
            // this.$toast(this.$i18n.t("m.qsydzdl"));
          }
        });
      },
      gbsmenu(){
      this.menuShows=false
    },
      onSelect(item) {
        this.lang=item
        let val=item.value
        this.$i18n.locale = val;
        this.$store.commit("language", val);
        this.show=false
      },
      menuopen() {
      this.active=this.$route.path;
        this.menuShows = true;
      },
      toPage(e) {
        if (e == this.active) {
          return;
        }
        this.menuShows = false;      
        if(e){
        this.handleFiexdToolItem(e)
        }
      },
      to(url) {
         window.open(url)
      },
     
      handleFiexdToolItem(e){
      // this.$notify(this.$i18n.t("m.wxtszwkf"))
      // return
    this.$router.push({path:e})
  },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $t-mf: "PingFang";
  $t-f: "DINAlternate-Bold";
  .top{
      display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 28px;
      .logo{
        width: 122px;
        height: 88px;
        .icosn{
          width: 100%;
          height: 100%;
        }
      }
      .right{
        display: flex;
    align-items: center;
      }
      .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 140px;
      height: 72px;
      background: #1C1C1C;
border: 2px solid #737373;
      border-radius: 36px;
      padding: 0 32px;
      .txicon {
        width: 44px;
        height: 44px;
      }
      .lang{
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 42px;
        margin-left: 20px;
        display: flex;
      }
      }
      .menu{
        display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
  height: 72px;
  background: #1C1C1C;
border: 2px solid #737373;
  border-radius: 22px;
  cursor: pointer;
  margin-left: 14px;
      .ico{
        width: 38px;
        height: 28px;
      }
      }
    }
    .sfelx{
        position: relative;
        padding: 56px;
        box-sizing: border-box;
        .gb{
          position: absolute;
          top: 44px;
          right: 40px;
          width: 42px;
          height: 40px;
        }
        .mentop{
          display: flex;
          align-items: center;
          margin-top: 104px;
          .logo{
            width: 38px;
            height: 38px;
          }
          .address{
            font-size: 28px;
            color: #FFFFFF;
            font-weight: bold;
            margin-left: 8px;
          }
          .level{
            min-width: 80px;
            height: 36px;
            padding: 0 8px;
            margin-left: 10px;
            background: linear-gradient( 90deg, #B0B0B0 0%, #7B7B7B 100%);
            border-radius: 18px;
            border: 2px solid #FFFFFF;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 32px;
            .ico{
              width: 100%;
              height: 100%;
            }
          }
        }
        .menulist{
          margin: 44px 0;
          .menuitem{
            width: 432px;
            height: 64px;
            border-radius: 12px;
            color: #ffffff;
            font-size: 32px;
            font-family: $t-mf;
            display: flex;
            align-items: center;
            padding-left: 26px;
            margin: 26px 0;
            cursor: pointer;
          }
          .act{
            background: #31704B;
            color: #FFFFFF;
          }
        }
        .foot{
          margin-top: 358px;
          .link{
            display: flex;
            align-items: center;
            .limg{
              width: 50px;
              height: 50px;
              margin-right: 56px;
            }
          }
          .price{
            margin: 22px 0;
            font-size: 22px;
            color: #A0A0A0;

          }
        }
      }
  </style>
  