<template>
  <div class="main">
    <Top/>
   
    <div class="content">
        <div class="introduce">
          <div class="title">{{infoTitle}}</div>
          <div class="desc">{{infoDec}}</div>
        </div>
        <div class="card" v-if="userLevelId==0">
          <img src="../assets/img/vip.png" alt="" class="vip" />
          <div class="ctop">
            <div class="title">VulcanX会员</div>
            <div class="level">未激活</div>
          </div>
          <div class="desc">
            开通VulcanX会员获得以下权益
          </div>
          <div class="tips">
            <div class="devs">免费领取1000USDT</div>
            <div class="devs">U1矿机*1</div>
            <div class="devs">1000GAS燃料</div>
          </div>
          <div class="bottom">
              <!-- <div class="btn" @click="toOpen()">去开通(${{vips}})</div> -->
              <van-button :loading="loading" type="primary" @click="toOpen()" class="btn">去开通(${{vips}})</van-button>
          </div>
        </div>

        <div class="card" >
          <div class="ctop">
            <div class="title">免费领{{maxUsdt}}USDT活动</div>
            <!-- <div class="runing" v-if="userLevelId>0">进行中</div>
            <div class="level" v-else>未激活</div> -->
          </div>
          <div class="desc">
            免费入群即送{{maxUsdt}}USDT
          </div>
          <div class="desc">每推荐1名好友入群奖励5USDT上不封顶
          </div>
          <div class="cont">
            <div class="topx">
            <div class="vv">当前进度 {{userInfo.inviteActivityGetUsdtMax}}USDT</div>
            <div class="ll">{{ll}}%</div>
          </div>
            <div class="line" >
              <div :class="ll?'act':''" :style="{width:ll+'%'}"></div>
            </div>
            <div class="nnr">{{userInfo.inviteActivityGetUsdt}}/{{userInfo.inviteActivityGetUsdtMax}}</div>

          </div>
          <div class="bottom">
              <div class="btn" @click="toPage('/Invite')">去分享</div>
          </div>
        </div>
    </div>
    <div class="ntitle">
      购买矿机
    </div>
    <div class="list">
      <div class="item_card" v-for="(item,i) in vStock" :key="i">
          <div class="title">
            {{item.machineName}}
          </div>
          <div class="desc">{{item.introduce}}</div>
          <div class="linex">
            <div class="ll">收益</div>
            <div class="rr">
              <div class="num">{{(item.dayOutput*30).toFixed(2)||0}}</div>
              <div class="dw"> USDT</div>
            </div>
          </div>
          <div class="linex">
            <div class="ll">价格</div>
            <div class="rr">
              <div class="num">{{item.machinePrice}}</div>
              <div class="dw"> USDT</div>
            </div>
          </div>
          <div class="linex">
            <div class="ll">燃料费</div>
            <div class="rr">
              <div class="num">{{item.monthFuel}}</div>
              <div class="dw"> GAS</div>
            </div>
          </div>
          <div class="linex">
            <div class="ll">所需等级</div>
            <div class="rr">
              <div class="dw"> {{item.levelName}}</div>
            </div>
          </div>
          <div class="bottom">
            <!-- <div class="btn" @click="toBuy(item)">购买</div> -->
            <van-button :loading="loading" type="primary" @click="toPay(item)" class="btn">购买</van-button>
          </div>
      </div>

     
    </div>
    <div class="buttoms">
        <div class="link">
          <img src="../assets/img/ico1.png" alt="" class="ico"  @click="toUrl('https://t.me/vulcanusdt')">
          <!-- <img src="../assets/img/ico2.png" alt="" class="ico" />
          <img src="../assets/img/ico3.png" alt="" class="ico" /> -->
        </div>
        <div class="xxx">Copyright © 2024. All rights reserved.</div>
      </div>

   

    <van-popup v-model="bdshows" round :close-on-click-overlay="!bdshows">
      <div class="popup">
        <div class="title">推荐人信息</div>
        <div class="txt">请填写邀请码（必填）</div>
        <div>
          <input type="text" class="input" v-model="yqmcode" />
        </div>
        <van-button :loading="bdloading" type="primary" @click="tobding" class="btn" loading-text="绑定中...">
          确认</van-button>
      </div>
    </van-popup>
    <van-popup v-model="shows" round :close-on-click-overlay="false">
      <div class="popup">
        <div class="title">连接钱包</div>
        <div class="txt">如要使用完整功能，请先连接您的钱包</div>
        <div class="btn" @click="ethConnect">连接钱包 <van-loading v-if="ljloading" /></div>
      </div>
    </van-popup>

    <van-popup v-model="payShow" round :close-on-click-overlay="false" >
      <div class="popup">
        <img
          src="../assets/img/gb.png"
          alt=""
          class="gb"
          @click="payShow = false"
        />
        <div class="title">选择支付类型</div>
        <div class="txt">支付金额：{{payInfo.machinePrice}}USDT</div>
        <div class="ubuttom">
        <div class="btn" @click="toBuys">余额支付 <van-loading v-if="byloading" /></div>
        <div class="btn" @click="toBuy(payInfo)">钱包支付 <van-loading v-if="byloading" /></div>
      </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { identity,withdraw,toSign ,getuserBalance} from "@/api/Public";
import Top from '@/components/top.vue'
export default {
  components:{Top},
  data() {
    return {
      shows: false,
      userWallet:"",
      menuShows:false,
      ljloading:false,
      ll:0,
      vStock:[],
      loading:false,
      bdshows:false,
      bdloading:false,
      yqmcode:'',
      infoTitle:'',
      userInfo:'',
      infoDec:'',
      userLevelId:0,
      myUsdt:0,
      vips:50,
      maxUsdt:1000,
      payInfo:"",
      byloading:false,
      payShow:false
    }},
    beforeCreate() {
    var i = 0;
    var a = setInterval(() => {
    
      if (typeof window.tronWeb !== "undefined") {
        // 获取波场地址
        identity().then(
          (info) => {
            if (info.indexOf("T") == 0) {
              clearInterval(a);
                  this.$store.commit("userWallet", info);
              // this.shows = false;
              // this.yqmcode = this.getQueryString("code");
              this.userWallet=info
              this.getInfo(1)   
           
            } else {
              this.$notify("请使用波场地址登录游戏");
            }
          },
          () => {
            if (i > 5) {              
              clearInterval(a);
            }
            this.ljloading=false
            this.$notify("钱包失联了！！");
          }
        );
      }else{
       
      }

      i++;
    }, 1000);
  },
  filters:{
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{5})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "连接钱包";
      }
    },
  },
 async created(){
  if(!this.$store.getters.userWallet){
        this.shows = true;}
    this.userWallet = this.$store.getters.userWallet;
   await this.getcInfo()
   await  this.getVip()  
    
   this.getUsdt()
   if(!this.yqmcode){
     let a=setInterval(() => {    
        let yqmcode = this.getQueryString("code");
         if(yqmcode){
          this.yqmcode=yqmcode
          clearInterval(a)}
     },100)
     }
    setTimeout(() => {
      this.getStock();
    }, 3000);
   
  },
  methods:{
   async toPay(val){
      await this.getInfo()  
      await this.getUsdt()
      if(val.machineId==1&&this.userInfo.canBuyMachineV1==0){
        this.$notify("您该等级矿机的可购买数量不足");
        return
      }
      if(val.machineId==2&&this.userInfo.canBuyMachineV2==0){
        this.$notify("您该等级矿机的可购买数量不足");
        return
      }
      if(val.machineId==3&&this.userInfo.canBuyMachineV3==0){
        this.$notify("您该等级矿机的可购买数量不足");
        return
      }
      if(val.needUserLevelId-this.userInfo.userLevelId>0){
        this.$notify("您的等级不足");
        return
        
      }
      this.payInfo=val
      this.payShow=true
    },
    toBuys(){
      this.byloading=true
      this.$post({
        url: "v1/buyMachine",
        data: {machineId:this.payInfo.machineId,hash:"",payType:'money'},
        callback: (res) => {
          this.$nextTick(function () {
            this.byloading=false
            if (res.code==200) {   
              this.getInfo()          
              this.$notify({
                message:"购买成功" ,
                type: "success",
              });
            } else {
              this.$notify(res.msg)
            }
          });
        },
      });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    ethConnect() {
      if(this.ljloading){return}
      this.ljloading=true
      identity().then((info) => {
        if (info.indexOf("T") == 0) {
          toSign(info).then(res=>{
                if(res){
          this.$store.commit("userWallet", info);          
          this.shows = false; 
          this.userWallet = info;      
           this.getInfo(1)
                }else{
                  this.ljloading=false
                }
              })
        }else{
          this.ljloading=false
        } 
        () => {
            this.ljloading=false
            this.$notify("钱包未连接！！");
          }
      });
    },
    getcInfo(){
      this.$get({
        url: "v1/getConfigDetail",
        data: {name:'project_introduction,project_introduction_title'},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.infoTitle = res.data[1].value
              this.infoDec = res.data[0].value
            } else {
              // this.$notify(res.msg)
            }
          });
        },
      });
    },
    getVip(){      
      this.$get({
        url: "v1/getConfigDetail",
        data: {name:'vip_price,member_get_money'},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.vips = res.data[1].value
              this.maxUsdt=res.data[0].value
            } else {
              // this.$notify(res.msg)
            }
          });
        },
      });
    },
    
    getInfo(isup){
      if(!this.userWallet){return}
      
        this.$post({
        url: "v1/bindAddress",
        data: {address :this.userWallet,
          isRefreshToken:isup?1:0,
          parentAddress:this.yqmcode
        },
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false            
            this.ljloading=false
            if (res.code==200) {   
              this.userInfo=res.data.member
              this.userLevelId= this.userInfo.userLevelId
              this.ll=(this.userInfo.inviteActivityGetUsdt/this.userInfo.inviteActivityGetUsdtMax*100).toFixed(2)              
              sessionStorage.setItem('token',res.data.token)
              this.$store.commit("intID", this.userInfo.userLevelName);
              setTimeout(() => {
                this.getStock()
              }, 100);   
              // if(!this.userInfo.parentAddress){
              //   this.bdshows=true
              // }
            } else {
              if(res.code=='405'|| res.msg=='请通过邀请链接进入'){
                this.bdshows=true
                return
              }
              this.$notify(res.msg)
            }
          });
        },
      });
      },
    tobding(){
      if(!this.yqmcode){
        this.$notify('请输入邀请地址！')
        return
      }
      if(!this.bdloading){
        this.bdloading=true
      this.$post({
        url: "v1/bindAddress",
        data: {address :this.userWallet,parentAddress:this.yqmcode},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) { 
              this.bdshows=false            
                this.$notify({
                  message:"绑定成功" ,
                  type: "success",
                });
                this.userInfo=res.data.member
              this.ll=(this.userInfo.inviteActivityGetUsdt/this.userInfo.inviteActivityGetUsdtMax).toFixed(2)              
              sessionStorage.setItem('token',res.data.token)
              this.$store.commit("intID", this.userInfo.userLevelName);
            } else {
              this.$notify(res.msg)
            }
          });
        },
      });
    
    }
    },
    async getUsdt(){
     this.myUsdt= await getuserBalance(this.userWallet).then(res=>{return res})
    },
    toUrl(url){
      window.open(url)
    },
 
    getStock() {
      this.$post({
        url: "v1/machineList",
        data: {},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.code==200) {
              this.vStock = res.data
            } 
          });
        },
      });
    },
  async  toOpen(){
      let open =sessionStorage.getItem('opne')
      if(open==1){ 
        this.$notify("您已开通，请稍候");
        return}
      if(this.loading){
        return
      }
     await this.getInfo()
     await this.getUsdt()
      if(this.myUsdt-this.vips<0){
        this.$notify("您的余额不足");
        this.getUsdt()
        return
      }

      console.log(this.userWallet);
      if(this.userInfo.canopenVip==1){
        this.loading=true
      withdraw(this.vips,this.userWallet).then(res=>{
        if(res){
          this._toOpen(res)
        }else{
          this.loading=false
          // this._toOpen('83c9575716695a90af535f8dd07fad1c78e6eaef73c43325788b8cf5483daccc')
          this.$notify("开通失败");
        }
      })}else{
        this.$notify("您无法开通！");
      }
    },
    _toOpen(hash){
      this.$post({
        url: "v1/openVip",
        data: {hash:hash},
        callback: (res) => {
          this.$nextTick(function () {
            this.loading=false
            if (res.code==200) {  
              sessionStorage.setItem('open',1)           
              this.$notify({
                message:"开通成功" ,
                type: "success",
              });
            } else {
              this.$notify(res.msg)
            }
          });
        },
      });
 
    },
    async toBuy(val){
    
      if(this.byloading){
        return
      }
      if(this.myUsdt-val.machinePrice<0){
        this.$notify("您的余额不足");
        this.getUsdt()
        return
      }
      this.byloading=true
      withdraw(val.machinePrice,this.userWallet).then(res=>{
        if(res){
          this._toBuy(val.machineId,res)
        }else{
          this.byloading=false
          // this._toBuy(val.machineId,'83c9575716695a90af535f8dd07fad1c78e6eaef73c43325788b8cf5483daccc')
          this.$notify(res.msg);
        }
      })

    },
    _toBuy(machineId,hash){
      this.$post({
        url: "v1/buyMachine",
        data: {machineId:machineId,hash:hash,payType:'hash'},
        callback: (res) => {
          this.$nextTick(function () {
            this.byloading=false
            if (res.code==200) {   
              this.getInfo()          
              this.$notify({
                message:"购买成功" ,
                type: "success",
              });
            } else {
              this.$notify(res.msg)
            }
          });
        },
      });
    },
    toPage(e){  this.$router.push({path:e})},
  }
}

</script>

<style lang="scss" scoped>
$t-mf: "PingFang";
$t-f: "Alibaba";
  .main{
    background:  #000000  no-repeat;
    min-height: 100vh;
    background-size: cover;
    overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
   
    .content{
      padding-top: 20px;
      .introduce{

        width: 692px;
min-height: 238px;
background: #1C1C1C;
border: 2px solid #737373;
box-shadow: 0px -2 0px 0px #5D2E5A;
border-radius: 20px;
padding: 12px 28px;
margin: 0 auto;
box-sizing: border-box;
.title{
min-height: 76px;
font-family: $t-mf;
font-weight: 600;
font-size: 52px;
color: #FFFFFF;
line-height: 74px;
text-align: left;
font-style: normal;
}
.desc{
  color: #FFFFFF;
  font-size: 28px;
  text-align: left;
  margin-top: 10px;
}
      }
      .card{
        width: 692px;
min-height: 318px;
// background: linear-gradient( 145deg, #2B406F 0%, #2A1D3D 38%, #4B0D67 100%);
// box-shadow: 0px -2 0px 0px #5D2E5A;
background: #1C1C1C;
box-shadow: 0px -1px 0px 0px #1C1C1C;
border-radius: 20px;
border: 2px solid #737373;
margin: 16px auto;
position: relative;
padding: 28px;
box-sizing: border-box;
.vip{
  position: absolute;
  width: 148px;
  height: auto;
  top: 28px;
  right: 28px;

}
        .ctop{
          display: flex;
          align-items: center;
          .title{
        font-size: 38px;
        font-family: $t-f;
          background: linear-gradient(90deg, #AE5EF8,  #D578FF 49%, #7FCFFA 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-right: 8px;
          }
          .runing{
            font-weight: 600;
font-size: 22px;
color: #FFFFFF;
line-height: 32px;
text-align: center;
font-style: normal;
width: 88px;
height: 36px;
background: linear-gradient( 90deg, #C1BC39 0%, #4C8D17 100%);
border-radius: 18px;
border: 2px solid #D5DE5D;
display: flex;
align-items: center;
justify-content: center;
          }
          .level{
            width: 66px;
height: 32px;
font-family: $t-mf;
font-weight: 600;
font-size: 22px;
color: #FFFFFF;
line-height: 32px;
text-align: center;
font-style: normal;
width: 88px;
height: 36px;
background: linear-gradient( 90deg, #B0B0B0 0%, #7B7B7B 100%);
border-radius: 18px;
border: 2px solid #FFFFFF;
display: flex;
align-items: center;
justify-content: center;

          }         
        }
        .desc{
            font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 28px;
color: #A88BC6;
line-height: 40px;
text-align: justify;
font-style: normal;
margin-top: 16px;
          }
          .tips{
            display: flex;
            align-items: center;
            .devs{
              color: #E9AB6C;
              font-size: 22px;
              font-weight: bold;
              font-family: $t-mf;
              position: relative;
              padding-left: 10px;
              margin-right: 16px;
              margin-top: 5px;
            }
            .devs::before{
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateX(-50%);
              width: 8px;
              height: 8px;
              border-radius: 100%;
              background: #E9AB6C;
            }
          }
          .cont{
            margin-top: 32px;
            .topx{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .vv{
                font-size: 24px;
                color: #F6F6F6;                
              }
              .ll{
                font-size: 24px;
                color: #F6F6F6;
                font-weight: bold;
              }
            }
            .line{
              background: #ffffff2e;
              width: 100%;
              height: 14px;
              border-radius: 7px;
              margin: 12px auto;
            }
            .act{
              background: rgba(255,255,255,0.4);
              height: 14px;
              border-radius: 7px;
            }
            .nnr{
              color: #FFFFFFFF;
              font-size: 24px;
              font-weight: bold;
              width: 100%;
              text-align: right;
              opacity: 0.5;
            }
          }
          .bottom{
            margin: 26px auto;
            .btn{
              border: none;
              background: #31704B;
              border-radius: 40px;
              width: 624px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              font-weight: bold;
              font-family: $t-mf;
              font-size: 32px;
              cursor: pointer;
            }
          }
      }
    }
    .ntitle{
      background: #ffffff;
      font-size: 36px;
      font-family: $t-f;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
      margin: 34px;     
    }
    .list{
      margin: 8px 0;
      padding:0  18px;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
        .item_card{
          min-width: 454px;
          background: #1D1D1D;
border: 1px solid #575757;
border-radius: 20px;
padding: 22px;
box-sizing: border-box;
margin: 24px;
.title{
  font-size: 32px;
  color: #FFFFFF;
  font-weight: bold;
  font-family: $t-mf;
  text-align: left;
}
.desc{
  font-size: 24px;
  color: #FFFFFF;
  font-family: $t-mf;  
  text-align: left;
  margin: 18px 0;
}
.linex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0;
  .ll{
    font-size: 24px;
    font-weight: $t-mf;
    font-weight: bold;
    color: #757575;
  }
  .rr{
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: $t-mf;
    .num{
      color: #E9AB6C;
    }
    .dw{
      color: #FFFFFF;
    }
  }
}
.bottom{
  .btn{
    border: none;
    width: 312px;
height: 80px;
background: #31704B;
border-radius: 40px;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
font-size: 32px;
font-weight: bold;
font-family: $t-mf;
margin: 34px auto 0;
cursor: pointer;
  }
}
        }
      }

      .buttoms{
        font-size: 24px;
        color: #A0A0A0;
        padding: 24px  24px 0;
        .xxx{
          padding-bottom: 24px;
        }
        .link{
          display: flex;
          align-items: center;
          justify-content: center;
          .ico{
            width: 50px;
            height: 50px;
            margin: 20px 29px;
          }
        }
      }


      .popup {
    width: 672px;
    background: #ffffff;
    border-radius: 30px;
    position: relative;

    .title {
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #222222;
      line-height: 66px;
      margin: 44px auto 0;
    }
    .gb{
          position: absolute;
          top: 0;
          right: 16px;
          width: 42px;
          height: 40px;
        }
    .btn {
      width: 80%;
      height: 80px;
      background: linear-gradient( 145deg, #31704B 0%, #31704B 38%, #31704B 100%);
      box-shadow: 0px -2 0px 0px #5D2E5A;
      border: none;
      border-radius: 40px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
      display: flex;
      justify-content: center;
    }
    .ubuttom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px;
      .btn{
        margin: 54px 10px;
      }
    }
    .input {
      border: none;
      width: 80%;
      height: 50px;
      font-size: 20px;
      background: #f8f8f8;
      border-radius: 50px;
      margin: 10px auto;
      padding: 10px 20px;
      font-size: 28px;
    }
    .txt {
      font-size: 28px;
      font-family: $t-mf;
      font-weight: 600;
      color: #333333;
      line-height: 40px;
      text-align: center;
      margin: 16px auto;
      // padding-left: 32px;
    }

  }
     
  }
</style>