<template>
    <div class="main">
        <div class="top">
      <div class="back" @click="toPage('/')">
        <img src="../assets/img/back.png" alt=""  />
      </div>
      <div class="title">
       邀请好友
      </div>
    </div> 
      <div class="ntop">
      <div class="ntitle">
        邀请好友
    </div>
    <img src="../assets/img/yqico.png" alt="" class="yqico" />
    </div>
      <div class="content"> 
          <div class="card">
           <div class="titles">邀请好友</div>
           <div class="qur">
            <vue-qr
                :text="codeWallet"
                :size="200"
                :logoScale="0.2">
            </vue-qr>
           </div>
            <div class="box">
              <div class="slink" >
                <div class="txt">邀请链接</div> 
                <div class="mylink">        
              <div class="nnr">{{codeWallet}}</div>
              <button
                type="button"
                class="fuzhi"
                v-clipboard:copy="codeWallet"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
            >
                <img src="../assets/img/fz.png" alt="" class="icon" />
            </button>
        </div>    
            </div>
              <div class="desc">
                通过二维码或邀请链接分享给你的伙伴
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
                <div class="slink">
                    <div class="txt">推荐人</div>
                    <div class="add">{{userInfo.parentAddress||'-/-'}}</div>
                </div>
            </div>
          </div>
      </div>
    
  
    </div>
  </template>
  
  <script>
  import { identity } from "@/api/Public";
  import vueQr from 'vue-qr'
  export default {
    components: {
            vueQr
        },
    data() {
      return {
        userWallet:"",
        userInfo:'',
        codeWallet:"https://www.vulcanx.vip/index.html?code="+this.$store.getters.userWallet,
        ll:60
      }},
      beforeCreate() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          // 获取波场地址
          identity().then(
            (info) => {
              if (info.indexOf("T") == 0) {
                clearInterval(a);
                this.$store.commit("userWallet", info);
                // this.yqmcode = this.getQueryString("code");
                this.userWallet=info
              } else {
                this.$notify("请使用波场地址登录游戏");
              }
            },
            () => {
              if (i > 5) {
                this.shows = true;
                clearInterval(a);
              }
              this.$notify("钱包失联了！！");
            }
          );
        }
  
        i++;
      }, 1000);
    },
    filters:{
      yingcang(val) {
        if (val) {
          let a = val.replace(/(\w{5})\w+(\w{8})/, "$1...$2");
          return a;
        } else {
          return "...";
        }
      },
    },
    created(){
      this.userWallet = this.$store.getters.userWallet;
      this.getInfo()
    },
    methods:{
      getInfo(){
        this.$post({
        url: "v1/bindAddress",
        data: {address :this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) {
              sessionStorage.setItem('token',res.data.token)   
              this.userInfo=res.data.member
              this.codeWallet="https://www.vulcanx.vip/index.html?code="+this.userWallet
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });
      },
      gbsmenu(){
        this.menuShows=false
      },
      onCopy() {
      if(this.$store.getters.userWallet){
        this.$notify({
                  message:"复制成功" ,
                  type: "success",
                });
      }
    },
    onError() {
      this.$notify("复制失败");
    },
      toPage(e){  this.$router.push({path:e})},
    }
  }
  
  </script>
  
  <style lang="scss" scoped>
  $t-mf: "PingFang";
  $t-f: "Alibaba";
    .main{
      background:  #000000  no-repeat;
      min-height: 100vh;
      background-size: 100% 100%;
      overflow-x: hidden;
    width: 750px;
    margin: 0 auto;
    .top { 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
        position: relative;
      .back {
      position: absolute;
    left: 20px;
       width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
      .title{
         background: linear-gradient(234deg,#ffffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }
}
      .content{
    
        .card{
            width: 692px;
            background: #1C1C1C;
box-shadow: 0px -1px 0px 0px #1C1C1C;
border: 2px solid #737373;
border-radius: 20px;
  margin: 16px auto;
  position: relative;
  padding: 28px;
  box-sizing: border-box;
       .titles{
        font-size: 32px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: left;
       }
       .qur{
        margin: 36px auto;
       }
       .box{
        .slink{
            display: flex;
            align-items: center;
           
            .txt{
                font-size: 30px;
                color: #FFFFFF;
                margin-right: 14px;
                white-space: nowrap;
            }
            .add{
                color: #ffffff;
                font-size: 24px;
                font-weight: bold;
                overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
            }
            .mylink{
                background: rgba(255,255,255,0.1);
                border-radius: 30px;
                height: 60px;
                width: 480px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 22px ;
                box-sizing: border-box;
            .nnr{
                font-size: 22px;
                color: #FFFFFF;
                font-weight: bold;
                overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
            }
.fuzhi{
    background: transparent;
        border: none;
    .icon{
        width: 34px;
        height: 34px;
        cursor: pointer;
    }
}


            }
          
        }

        .desc{
    color: #757575;
    font-size: 24px;
    margin-top: 16px;
    text-align: left;
}
       }
           
           
        }
      }
      .ntop{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 34px 28px;
      .ntitle{
      background: linear-gradient(234deg,#ffffff, #ffffff);
      font-size: 84px;
      line-height: 116px;
      font-family: $t-f;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;    
      white-space: nowrap;     
    }
    .yqico{
        width: 364px;
        height: 292px;
    }
    }
  
     
    }
  </style>