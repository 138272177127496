<template>
  <div class="main">
    <Top/>
    <div class="ntop">
      <div class="ntitle">
      我的矿池
    </div>
      <div class="nbtn" @click="toDhShows">
        兑换燃料 <van-loading v-if="loadings" />
      </div>
    </div>
    <div class="content"> 
      <div class="cards">
          <div class="item">
            <div class="num">{{userInfo.machineAccumulatedIncome||0}}</div>
            <div class="txt">累计收益(USDT)</div>
          </div>
          <div class="item">
            <div class="num">{{userInfo.gasTotal||0}}</div>
            <div class="txt">剩余燃料(GAS)</div>
          </div>
      </div>       
        <div class="card" v-for="(item,i) in vStock" :key="i">          
          <div class="ctop">
            <div class="left">
            <div class="title">{{item.machineName}}</div>
            <div class="time">购买时间：{{item.createTime|fextime}}</div>
           </div>
            <div class="level act" v-if="item.status==1">进行中</div>
            <div class="level " v-if="item.status==0">停止</div>
          </div>
                   
          <div class="cont">
              <div class="item">                  
                  <div class="txt">累计产出</div>
                  <div class="num" v-if="item">{{item.outputWithdraw||0}} <div class="dw">USDT</div></div>
              </div>
              <div class="item">                
                  <div class="txt">所需燃料(月)</div>
                  <div class="num">{{item.monthFuel||0}} <div class="dw">GAS</div></div>
              </div>
              <div class="item">                  
                  <div class="txt">挖矿收益</div>
                  <div class="num">{{item.mineIncomeRate||0}} <div class="dw">%</div></div>
              </div>
          </div>
          <div class="box">
          <!-- <div class="line" >
              <div :class="ll?'act':''" :style="{width:ll+'%'}"></div>
          </div> -->
          <div class="tips">当前燃料可运行周期剩余 <span class="sn">{{item.lastDay||0}}天</span> </div>
        </div>
          <div class="bottom">
            <div class="btns" @click="toBcShow(item)" >补充燃料</div>
              <div class="btn" @click="toDetail(item)">收益明细</div>
          </div>
        </div>

       
    </div>
  

      <!-- 菜单 -->
    <van-popup
      v-model="menuShows"
      position="right"
      :style="{ height: '100%',background:'#1E133D'}"
    >
    <div class="sfelx" >
      <img src="../assets/img/gb.png" alt="" class="gb" @click="gbsmenu"/>
      <div class="mentop">       
        <img src="../assets/img/trx.png" alt="" class="logo" />
        <div class="address">{{userWallet|yingcang}}</div>
        <div class="level">
          <!-- <img src="../assets/img/lv1.png" alt="" class="ico" /> -->
          {{this.$store.getters.intID}}
        </div>        
      </div>
      <div class="menulist">
        <div class="menuitem fadeInLeft" @click="toPage('/')">首页</div>         
        <div class="menuitem  fadeInLeft" @click="toPage('/Team')">团队</div>
        <div class="menuitem act fadeInLeft" @click="gbsmenu">矿场</div>
        <div class="menuitem fadeInLeft" @click="toPage('/My')">我的</div>
      </div>      
      <div class="foot">
        <div class="link">
          <img src="../assets/img/ico1.png" alt="" class="limg"  @click="toUrl('https://t.me/vulcanusdt')">
          <!-- <img src="../assets/img/ico2.png" alt="" class="limg">
          <img src="../assets/img/ico3.png" alt="" class="limg"> -->
        </div>
        <div class="price">Copyright © 2024 项目名称. All rights reserved. </div>
      </div>
    </div>
    </van-popup>
    <van-popup
      v-model="dhShows"
      position="center"
      :style="{ background: '#1E133D' }"
    >
      <div class="pbox">
        <img
          src="../assets/img/gb.png"
          alt=""
          class="gb"
          @click="dhShows = false"
        />
        <div class="title">兑换燃料</div>
        <div class="tab"> 
          <div class="item" :class="payType=='money'?'act':''" @click="toChange('money')">余额支付</div>       
          <div class="item" :class="payType=='hash'?'act':''" @click="toChange('hash')">钱包支付</div>
        </div>
        <div class="box">
          <div class="sitem">
            <div class="input">
              <div class="dw">USDT</div>
              <input type="text" v-model="Num" placeholder="请输入数量" class="sinp" @input="jsNums">
            </div>
            <div class="banner">
              <div class="ll">Blance</div>
              <div class="num" v-if="payType=='hash'">{{(mybanUsdt*1).toFixed(2)||0}}</div>
              <div class="num" v-if="payType=='money'">{{userInfo.money}}</div>
            </div>
          </div>
          <img
          src="../assets/img/jt.png"
          alt=""
          class="ico"
        />
          <div class="sitem">
            <div class="input">
              <div class="dw">GAS</div>
              <input type="text" v-model="Nums" placeholder="请输入数量" class="sinp" @input="jsNum">
            </div>
            <div class="banner">
              <div class="ll">Blance</div>
              <div class="num" >{{userInfo.gasTotal}}</div>
            </div>
          </div>
        </div>
        <div class="cont">
            <div class="tt">你将获得</div>
            <div class="num">{{Nums}} GAS</div>
            <div class="line">
              <div class="ll">兑换比例</div>
              <div class="rr">
                <div class="snum">1</div>
                <div class="sdw">USDT=</div>
                <div class="snum">{{fate}}</div>
                <div class="sdw">GAS</div>
              </div>
            </div>
        </div>
        <div class="bottom">
          <van-button :loading="loading" type="primary" @click="toyDhGas" class="btn" v-if="payType=='money'"
            >余额支付</van-button>
            <van-button :loading="loading" type="primary" @click="toDhGas" class="btn" v-if="payType=='hash'"
            >钱包支付</van-button>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="bcShows"
      position="center"
      :style="{ background: '#1E133D' }"
    >
      <div class="pbox">
        <img
          src="../assets/img/gb.png"
          alt=""
          class="gb"
          @click="bcShows = false"
        />
        <div class="title">补充燃料</div>
        <div class="sntop">
            <div class="ll">可用燃料</div>
            <div class="rr">{{userInfo.gasTotal||0}}GAS</div>
          </div>
        <div class="sbox">       
          <div class="item" :class="bcNums==item.value?'act':''" v-for="(item,i) in nlist" :key="i" @click="toSnuym(item)">
            {{item.value}}GAS({{item.key}}月)</div>   

          <div class="items">自定义<input type="text" placeholder="请输入" class="input" v-model="bcNum" @input="jsbcNum"> 月</div>        
        </div>
        <div class="bottom">
          <van-button :loading="bdloading" type="primary" @click="toReplenish" class="btn"
            > {{canbuy?'确认补充':'燃料不足'}}</van-button
          >
          <div class="btns" @click="toDhShows()">兑换燃料<van-loading v-if="loadings" /></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { identity,withdraw,getuserBalance,toSign } from "@/api/Public";
import vueQr from 'vue-qr'
import Top from '@/components/top.vue'
export default {
  components: {
          vueQr,Top
      },
  data() {
    return {
      userWallet:"",
      canbuy:true,
      menuShows:false,
      dhShows:false,
      bcShows:false,
      codeWallet:"https://xxx.xxxxxxxxxxxxx.com/a316516qweeeeeeeeeeeeeeeeeeeeeeee…",
      ll:60,
      fate:50,
      vStock:[],
      Num:'',
      Nums:"",
      userInfo:'',
      mybanUsdt:'',
      loading:false,
      loadings:false,
      bdloading:false,
      nlist:[],
      userMachineId:"",
      bcYue:0,
      bcNum:'',
      bcNums:'',
      payType:'money'
    }},
    beforeCreate() {
    var i = 0;
    var a = setInterval(() => {
      if (typeof window.ethereum !== "undefined") {
        // 获取波场地址
        identity().then(
          (info) => {
            if (info.indexOf("T") == 0) {
              clearInterval(a);                 
            if(info==this.$store.getters.userWallet){return}
              this.$store.commit("userWallet", info);
              // this.yqmcode = this.getQueryString("code");
              this.userWallet=info
              this.getInfo()
            } else {
              this.$notify("请使用波场地址登录游戏");
            }
          },
          () => {
            if (i > 5) {
              this.shows = true;
              clearInterval(a);
            }
            this.$notify("钱包失联了！！");
          }
        );
      }

      i++;
    }, 1000);
  },
  filters:{
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{5})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "连接钱包";
      }
    },
    fextime(val){
      if(val){
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        const date = new Date(val);
        return date.toLocaleString('zh-CN', options); 
      }      
    },
  },
 async created(){
    this.userWallet = this.$store.getters.userWallet;
   
   await this.getInfo()
  //  await this.getStock()
  },
  methods:{
    ethConnect() {
      identity().then((info) => {
        if (info.indexOf("T") == 0) {             
          if(info==this.$store.getters.userWallet){return}
          toSign(info).then(res=>{
                if(res){
          this.$store.commit("userWallet", info);
          this.userWallet = info;
          this.getInfo(1)
                }})
        } 
      });
    },
    toChange(e){
      this.payType=e
      if(e=='hash'){
        getuserBalance(this.userWallet).then(res=>{
                this.mybanUsdt=res
              })
           
      }else{
        this.getInfo()
      }
    },
    getInfo(isup){
      if(!this.userWallet){return}
        this.$post({
        url: "v1/bindAddress",
        data: {address :this.userWallet, isRefreshToken:isup?1:0,},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) {   
              sessionStorage.setItem('token',res.data.token)
              this.userInfo=res.data.member
              setTimeout(() => {
                this.getStock()
              }, 1000);            
            } else {
              // this.$toast(res.msg)
            }
          });
        },
      });
      },
      toSnuym(e){
        this.bcNums=e.value
        this.bcNum='',
        this.bcYue=e.key
      },
      toBcShow(e){
        this.userMachineId=e.userMachineId
        this.$post({
        url: "v1/replenishmentFuelList",
        data: {machineId:e.machineId},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) {   
              this.nlist= this.option= Object.entries(res.data).map(([key, value]) => ({ key, value }))
              console.log(this.nlist);
              this.bcShows=true
            } else {
              // this.$toast(res.msg)
            }
          });
        },
      });
      
      },
      jsbcNum(){
        setTimeout(() => {
          this.bcNums=''
          this.bcYue=''
        }, 100);
      },
      toReplenish(){
       
        if(this.bcYue>0||this.bcNum>0){
          if(this.bdloading){return}
        this.bdloading=true
            this.$post({
        url: "v1/startMachine",
        data: {month:this.bcYue>0?this.bcYue:this.bcNum,userMachineId:this.userMachineId},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) {   
              this.$notify({
                message:"补充成功" ,
                type: "success",
              });
              this.getStock()
              this.bcShows=false
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });
          }
        
      },
      toDhGas(){
      if(this.loading){return}
      
      if(this.mybanUsdt-this.Num<0){
        this.$notify("您的余额不足");
        return
      }
      if(this.userWallet&&this.Num>0){
      this.loading=true
      withdraw(this.Num,this.userWallet).then(res=>{
        if(res){
          this._toDhGas(res)
        }else{
          this.loading=false
          // this._toDhGas('83c9575716695a90af535f8dd07fad1c70528e6eaef73c43325788b8cf5483daccc')
          this.$notify("购买失败" )
        }
      })
    }
      },
      _toDhGas(hash){
        this.$post({
        url: "v1/usdtToGas",
        data: {usdtTotal:this.Num,hash:hash,payType:'hash'},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) {   
              this.getInfo()
              this.dhShows=false
              this.$notify({
                message:"兑换成功" ,
                type: "success",
              });
            } else {             
              this.$toast(res.msg)
            }
          });
        },
      });
      },
      toyDhGas(){
        if(this.Num-this.userInfo.money>0){
          this.$notify('您的余额不足')
          return
        }
        this.$post({
        url: "v1/usdtToGas",
        data: {usdtTotal:this.Num,hash:'',payType:'money'},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) {   
              this.getInfo()
              this.dhShows=false
              this.$notify({
                message:"兑换成功" ,
                type: "success",
              });
            } else {             
              this.$toast(res.msg)
            }
          });
        },
      });
      },
    jsNum(){
      setTimeout(() => {
        this.Num=this.Nums/this.fate
      }, 100);
     
    },
    jsNums(){
      setTimeout(() => {
      this.Nums=this.Num*this.fate
    }, 100);
    },
    toDhShows(){
      if(this.loadings){return}
      this.loadings=true
      this.bcShows=false
      this.$get({
        url: "v1/getConfigDetail",
        data: {name:'exchange_fiel_rate'},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.payType='money'
              getuserBalance(this.userWallet).then(res=>{
                this.mybanUsdt=res
                this.dhShows=true
                this.loadings=false
              })
           
              this.fate = res.data[0].value
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });
      
    },
    getStock() {
      this.$post({
        url: "v1/userMachineList",
        data: {},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data.list) {
              this.vStock =res.data.list.data
            } else {
              if(res.mg=='Token已过有效期'||res.msg=='Token验证失败'){
                this.getInfo(1)
              return
              }
              this.$toast(res.msg)
            }
          });
        },
      });
    },
    toDetail(e){
      this.$store.commit("myID", e.userMachineId);
      this.toPage('/Detail')
    },
    gbsmenu(){
      this.menuShows=false
    },
    onCopy() {
    if(this.$store.getters.userWallet){
    this.$toast('复制成功');
    }
  },
  onError() {
    this.$toast("复制失败");
  },
  toUrl(url){
      window.open(url)
    },
    toPage(e){  this.$router.push({path:e})},
  }
}

</script>

<style lang="scss" scoped>
$t-mf: "PingFang";
$t-f: "Alibaba";
  .main{
    background:  #000000   no-repeat;
    min-height: 100vh;
    background-size: 100% 100%;
    overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
    .top{
      display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 28px;
      .logo{
        width: 122px;
        height: 88px;
        .icosn{
          width: 100%;
          height: 100%;
        }
      }
      .right{
        display: flex;
    align-items: center;
      }
      .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 140px;
      height: 72px;
      background: linear-gradient( 145deg, #2B406F 0%, #2A1D3D 38%, #4B0D67 100%);
      box-shadow: 0px -2 0px 0px #5D2E5A;
      border-radius: 36px;
      padding: 0 32px;
      .txicon {
        width: 44px;
        height: 44px;
      }
      .lang{
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 42px;
        margin-left: 20px;
      }
      }
      .menu{
        display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
  height: 72px;
  background: linear-gradient( 145deg, #2B406F 0%, #2A1D3D 38%, #4B0D67 100%);
  box-shadow: 0px -2 0px 0px #5D2E5A;
  border-radius: 22px;
  cursor: pointer;
  margin-left: 14px;
      .ico{
        width: 38px;
        height: 28px;
      }
      }
    }
    .content{
      .cards{
        width: 692px;
height: 180px;
background: #1D1D1D;
border: 1px solid #575757;
border-radius: 20px;
margin:  26px auto;
display: flex;
align-items: center;
justify-content: space-around;
.item{
  .num{
    background: linear-gradient(0deg, #FFFFFF 0%, #ffffff 100%);
      font-size: 46px;
      font-family: $t-f;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  .txt{
    color: #757575;
    font-size: 28px;
    font-family: $t-mf;
  }
}
      }
  
      .card{
          width: 692px;
          background: #1D1D1D;
border: 2px solid #737373;
border-radius: 20px;
margin: 16px auto;
position: relative;
padding: 28px;
box-sizing: border-box;

        .ctop{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left{
            display: flex;
            align-items: center;
          }
          .title{
        font-size: 32px;
        font-family: $t-mf;
       color: #ffffff;
       font-weight: bold;
        margin-right: 8px;
        white-space:nowrap
          }
          .time{
            color: #ffffff;
            font-size: 22px;
            background: rgba(159,159,159,0.12);
            border-radius: 4px;
            padding: 8px;
            white-space:nowrap
          }
          .level{
font-family: $t-mf;
font-weight: 600;
font-size: 22px;
color: #FFFFFF;
line-height: 32px;
text-align: center;
font-style: normal;
width: 88px;
height: 36px;
background: linear-gradient( 90deg, #B0B0B0 0%, #7B7B7B 100%);
border-radius: 18px;
border: 2px solid #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
          }  
          .act{
            background: linear-gradient( 90deg, #C1BC39 0%, #4C8D17 100%);
border-radius: 18px;
border: 2px solid #D5DE5D;
          }
        }
       
     .cont{
      margin: 40px 12px ;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item{

          .num{
              color: #FFFFFF;
              font-size: 40px;
              font-weight: bold;
              line-height:56px;
              display: flex;
              align-items: flex-end;
          }
          .txt{
              font-size: 24px;
              color: #757575;
              margin-top: 12px;
          }
          .dw{
            font-size: 28px;
            line-height: 40px;
            color: #FFFFFF;
          }
      }
     }
   
     .titles{
      font-size: 32px;
      color: #FFFFFF;
      font-weight: 600;
      text-align: left;
     }

     .box{
      .line{
              background: #ffffff2e;
              width: 100%;
              height: 14px;
              border-radius: 7px;
              margin: 12px auto;
            }
            .act{
              background: rgba(255,255,255,0.4);
              height: 14px;
              border-radius: 7px;
            }
      .tips{
  color: #ffffff;
  font-size: 24px;
  margin-top: 16px;
  text-align: left;
  .sn{
    color:#E9AB6C ;
  }
}
   
     }
         
      
          .bottom{
            margin: 38px auto 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btns{
              background: rgba(255,255,255,0.1);
              border-radius: 40px;
              width: 280px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              font-weight: bold;
              font-family: $t-mf;
              font-size: 32px;
              cursor: pointer;
            }
            .btn{
              background: #31704B;
              border-radius: 40px;
              width: 280px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              font-weight: bold;
              font-family: $t-mf;
              font-size: 32px;
              cursor: pointer;
              margin-bottom: 16px;
            }
          }
      }
    }
    .ntop{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 34px 28px;
      .nbtn{
        min-width: 150px;
height: 50px;
background: #31704B;
border-radius: 40px;
display: flex;
      align-items: center;
      justify-content:center;
      color: #FFFFFF;
      font-size: 26px;
      font-weight: bold;
      cursor: pointer;
      }
      .ntitle{
      background: #ffffff;
      font-size: 36px;
      font-family: $t-f;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;         
    }
    }
 
    .list{
      margin: 8px;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
        .item_card{
          min-width: 454px;
background: linear-gradient( 180deg, #2E1E41 0%, #1B162C 100%);
box-shadow: 0px -2 0px 0px #5D2E5A;
border-radius: 20px;
padding: 22px;
box-sizing: border-box;
margin: 24px;
.title{
  font-size: 32px;
  color: #FFFFFF;
  font-weight: bold;
  font-family: $t-mf;
  text-align: left;
}
.desc{
  font-size: 24px;
  color: #826F94;
  font-family: $t-mf;  
  text-align: left;
  margin: 18px 0;
}
.linex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0;
  .ll{
    font-size: 24px;
    font-weight: $t-mf;
    font-weight: bold;
    color: #9581BC;
  }
  .rr{
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: $t-mf;
    .num{
      color: #D113FF;
    }
    .dw{
      color: #FFFFFF;
    }
  }
}
.bottom{
  .btn{
    width: 312px;
height: 80px;
background: #31704B;
border-radius: 40px;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
font-size: 32px;
font-weight: bold;
font-family: $t-mf;
margin: 34px auto 0;
  }
}
        }
      }

      .buttoms{
        font-size: 24px;
        color: #5D5879;
        padding: 24px ;
        .link{
          display: flex;
          align-items: center;
          justify-content: center;
          .ico{
            width: 50px;
            height: 50px;
            margin: 20px 29px;
          }
        }
      }


      .sfelx{
        position: relative;
        padding: 56px;
        box-sizing: border-box;
        .gb{
          position: absolute;
          top: 44px;
          right: 40px;
          width: 42px;
          height: 40px;
        }
        .mentop{
          display: flex;
          align-items: center;
          margin-top: 104px;
          .logo{
            width: 38px;
            height: 38px;
          }
          .address{
            font-size: 28px;
            color: #FFFFFF;
            font-weight: bold;
            margin-left: 8px;
          }
          .level{
            min-width: 80px;
            height: 36px;
            padding: 0 8px;
            margin-left: 10px;
            background: linear-gradient( 90deg, #B0B0B0 0%, #7B7B7B 100%);
            border-radius: 18px;
            border: 2px solid #FFFFFF;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 32px;
            .ico{
              width: 100%;
              height: 100%;
            }
          }
        }
        .menulist{
          margin: 44px 0;
          .menuitem{
            width: 432px;
            height: 64px;
            border-radius: 12px;
            color: #947AA6;
            font-size: 32px;
            font-family: $t-mf;
            display: flex;
            align-items: center;
            padding-left: 26px;
            margin: 26px 0;
            cursor: pointer;
          }
          .act{
            background: #31704B;
            color: #FFFFFF;
          }
        }
        .foot{
          margin-top: 358px;
          .link{
            display: flex;
            align-items: center;
            .limg{
              width: 50px;
              height: 50px;
              margin-right: 56px;
            }
          }
          .price{
            margin: 22px 0;
            font-size: 22px;
            color: #5D5879;

          }
        }
      }

      .pbox {
    position: relative;
    width: 672px;
    border-radius: 20px;
    background: #1D1D1D;
border: 2px solid #737373;
    padding: 28px;
    box-sizing: border-box;
    .gb {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 40px;
      height: 40px;
    }
    .title {
      color: #ffffff;
      font-size: 32px;
      font-weight: bold;
      font-family: $t-mf;
      margin-top: 28px;
    }
    .tab{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .item{
        width: auto;
        font-size: 28px;
        margin:0 22px;
        height: 80px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom:4px solid transparent;
      }
      .act{
border-bottom:4px solid #31704B;
        
      }
    }
    .box{
      display: flex;
      align-items: flex-start;
      justify-content: space-between; 
      margin-top: 46px; 
      .ico{
        width: 38px;
        // height: 20px;
        margin-top: 30px;
      }
    .sitem {
      width: 240px;       
      .input {
        height: 80px;
        background: #333333;
        border-radius: 6px;
        border: none;
        padding: 0 32px;
        color: #b0b0b0;
        font-size: 28px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

      }
      .sinp{
        width:120px;
        border: none;
        background: transparent;
      }
      .banner {
        color: #cbcbcb;
        font-size: 26px;
        font-family: $t-mf;
        text-align: right;
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

  }
  .cont{
    width: 100%;
height: 202px;
background: rgba(255,255,255,0.1);
border-radius: 12px;
margin-top: 38px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
padding: 26px;
box-sizing: border-box;
.tt{
  font-size: 24px;
  color:#B0B0B0 ;
}
.num{
  color: #FFFFFF;
  font-size: 40px;
}
.line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .ll{
    color: #B0B0B0;
    font-size: 24px;
  }
  .rr{
    display: flex;
    align-items: center;
    color: #B0B0B0;
    font-size: 24px;
    .snum{
      color: #ffffff;
      font-size: 34px;
      margin: 0 8px;
    }
  }
}
  }
  .sntop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #757575;
    font-size: 24px;
    margin: 30px 0;
  }
  .sbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
   
    .item{
      min-width: 192px;
      margin-top: 18px;
height: 72px;
background: #3B314D;
border-radius: 12px;
color: #ffffff;
font-size: 26px;
display: flex;
    align-items: center;
    justify-content: center;
    }
    .act{
      background: #31704B;
    }
    .items{
      flex: 1;
      margin: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      margin-top: 18px;
      font-size: 28px;
      .input{
        width: 192px;
height: 72px;
background: #333333;
border-radius: 12px;
color: #ffffff;
border: none;
padding: 0 16px;
      }
    }
  }
    .bottom {
      margin: 60px auto 40px;

      .btn {
        border: none;
        width: 600px;
        height: 80px;
        background: #31704B;
        color: #ffffff;
        font-size: 28px;
        font-weight: bold;
        border-radius: 40px;
      }
      .btns{
        color: #757575;
        font-size: 26px;
        margin-top: 26px;
      }
    }
  }
  }
</style>