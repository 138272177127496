import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userWallet: "" || sessionStorage.getItem("userWallet"),
    
     usdt: "" || sessionStorage.getItem("usdt"),
    myID: "" || sessionStorage.getItem("myID"),
    intID: "" || sessionStorage.getItem("intID"),
    myTrx: "" || localStorage.getItem("myTrx"),
    myFst: "" || localStorage.getItem("myFst"),
    myFht: "" || localStorage.getItem("myFht"),
    myGxt:"" || localStorage.getItem("myGxt"),
    language: localStorage.getItem('yy') || "zh-CN",
     theame:localStorage.getItem('theame') || '0',
  },
  mutations: {
    userWallet(state, val) {
      state.userWallet = val;
      sessionStorage.setItem("userWallet", val);
    },
    language(state,val){
      state.language = val
      localStorage.setItem('yy', val)
    },
     usdt(state, val) {
      state.usdt = val;
      sessionStorage.setItem("usdt", val);
    },
    myID(state, val) {
      state.myID = val;
      sessionStorage.setItem("myID", val);
    },
    intID(state, val) {
      state.intID = val;
      sessionStorage.setItem("intID", val);
    },
    myTrx(state, val) {
      state.myTrx = val;
      localStorage.setItem("myTrx", val);
    },
    myFst(state, val) {
      state.myFst = val;
      localStorage.setItem("myFst", val);
    },
    myFht(state, val) {
      state.myFst = val;
      localStorage.setItem("myFht", val);
    },
    myGxt(state, val) {
      state.myGxt = val;
      localStorage.setItem("myGxt", val);
    },
      theame(state, val) {
      state.theame = val;
      localStorage.setItem("theame", val);
    },
  },
  actions: {},
  modules: {},
  getters: {
    userWallet: (state) => state.userWallet,
    usdt: (state) => state.usdt,
    myID: (state) => state.myID,
    intID: (state) => state.intID,
    myTrx: (state) => state.myTrx,
    myFst: (state) => state.myFst,
    myFht: (state) => state.myFht,
    myGxt: (state) => state.myGxt,
    language: (state) => state.language,
     theame: (state)=>state.theame,
  },
});
