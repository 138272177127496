import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Team from '../views/team.vue'
import Pool from '../views/pool.vue'
import My from '../views/my.vue'
import Invite from '../views/invite.vue'
import Partner from '../views/partner.vue'
import Detail from '../views/detail.vue'
import MyDetail from '../views/mydetail.vue'
import Details from '../views/details.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Team',
    name: 'Team',
    component: Team
  },
  {
    path: '/Pool',
    name: 'Pool',
    component: Pool
  },
  {
    path: '/My',
    name: 'My',
    component: My
  },
  {
    path: '/Invite',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/Partner',
    name: 'Partner',
    component: Partner
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/Details',
    name: 'Details',
    component: Details
  },
  {
    path: '/MyDetail',
    name: 'MyDetail',
    component: MyDetail
  }
  
  
  
]

const router = new VueRouter({
  routes
})

export default router
