<template>
  <div class="main">
    <div class="top">
      <div class="back" @click="toPage('/Pool')">
        <img src="../assets/img/back.png" alt=""  />
      </div>
      <div class="title">
        提现明细
      </div>
    </div> 
          <div class="content">
            <div class="list">
              <div class="tabtop">
                <div class="ll1">交易哈希</div>
                <div class="ll2">时间</div>
                <div class="ll3">提现数量</div>
                <div class="ll4">手续费</div>
                <div class="ll4">实际到账</div>
              </div>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad"
                style="min-height: 80vh"
              >
              <div>
                <div v-for="(item, i) in list" :key="i" class="item">
                  <div class="ll1">{{item.hash | yingcang}}</div>                  
                  <div class="ll2">{{item.createTime |fextime}}</div>
                  <div class="ll3">{{item.cashMoney }}</div>
                  <div class="ll4">{{item.serviceMoney }}</div>
                  <div class="ll4">{{item.money}}</div>
                </div>
                    <div v-if="list.length == 0" class="noData">
              <img src="../assets/img/logo.png" alt=""  class="image" />
              <div class="ntxt">暂时没有数据</div>
            </div>
            </div>
            <div class="kongbai">

            </div>
              </van-list>
              
            </van-pull-refresh>
            
          </div>
          </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      list: [],
      lists: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
    };
  },
  filters:{
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{4})\w+(\w{4})/, "$1...$2");
        return a;
      } else {
        return "...";
      }
    },
    fextime(val){
      if(val){
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        const date = new Date(val);
        return date.toLocaleString('zh-CN', options); 
      }      
    },
  },
  methods: {
    toPage(e){  this.$router.push({path:e})},
    onClick(e) {
      console.log(e);
      this.active=e
      this.page = 1;
      this.loading = true;
      this.finished = false;
      setTimeout(() => {
        this.refreshing = true;
        this.onLoad();
      }, 100);
    },
    onLoad() {
      if (this.page == 1) {
        this.list = [];
        this.refreshing = false;
      }
      this.$post({
        url:'v1/withdrawList',
        data: {
          type : 'mine',
          userMachineId:this.$store.getters.myID||"",
        },
        callback: (res) => {
          this.$nextTick(function () {
            this.loading = false;
            if (res.data) {          
               if (this.page > 1) {
                this.list = [...this.list, ...res.data.data]
              } else {
                this.list = res.data.data
              }
              if (res.data.length == 10) {
                this.page++;
              } else {
                this.finished = true;
              }
            }else{
              this.finished = true;
            }
          });
        },
      });
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.main {
  background:  #000000  no-repeat;
      min-height: 100vh;
      background-size: 100% 100%;
      overflow-x: hidden;
    width: 750px;
    margin: 0 auto;
  ::v-deep.van-nav-bar__content {
    height: 80px !important;
  }
  ::v-deep.van-nav-bar__arrow {
    font-size: 36px;
  }
  ::v-deep.van-ellipsis {
    font-size: 32px;
    line-height: 44px;
  }
  .top { 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
        position: relative;
      .back {
      position: absolute;
    left: 20px;
       width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
      .title{
         background: linear-gradient(234deg,#ffffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }
}
  .list{
    .tabtop{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 722px;
height: 64px;
background: rgba(159,159,159,0.12);
border-radius: 4px;
margin: 16px auto;
padding: 0 14px;
box-sizing: border-box;
color: #ffffff;
font-size: 20px;
    }
    .item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      padding:20px 28px ;
      box-sizing: border-box;
      border-bottom: 1px solid #2F2B53;
      font-size: 24px;
    }
    .ll1{
      width:226px;
      text-align: left;
    }
    .ll2{
      width: 194px;
      text-align: left;
    }
    .ll3{
      width: 128px;
      text-align: left;
      margin-left: 38px;
    }
    .ll4{
      width: 148px;
      text-align: right;
    }
  }
   .noData{
		min-height: 50vh;
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
    .ntxt{
        line-height: 44px;
      font-size: 26px;
      color: #d5d5d5;
      font-weight: 600;
      font-family: $t-mf;
    }
		.image{
      width: 186px;
			margin: 30px auto;
		}
	}
  .kongbai{
  height: 1rem;
}
}
</style>



