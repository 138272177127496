<template>
    <div class="main">
      <Top/>
      <div class="ntop">
      <div class="ntitle">
        我的团队
    </div>
    </div>
      <div class="content">        
          <div class="card">          
            <div class="ctop">
              <div class="title">当前等级</div>
              <img src="../assets/img/yw.png" alt="" class="yw"  @click="toLevel"/>
            </div>
            <div class="level" v-if="userInfo">
               {{userInfo.userLevelName}}
            </div>          
            <div class="cont">
                <div class="item">
                    <div class="num">{{userInfo.inviteUserNum||0}}</div>
                    <div class="txt">直推人数</div>
                </div>
                <div class="item">
                    <div class="num">{{userInfo.teamUserNum||0}}</div>
                    <div class="txt">团队人数</div>
                </div>
                <div class="item">
                    <div class="num">{{userInfo.performance||0}}</div>
                    <div class="txt">消费额($)</div>
                </div>
            </div>
            <div class="bottom">
                <div class="btn" @click="toPage('/Partner')">直推列表</div>
            </div>
          </div>
  
          <div class="card">
           <div class="titles">邀请好友</div>
           <div class="qur">
            <vue-qr
                :text="codeWallet"
                :size="200"
                :logoScale="0.2">
            </vue-qr>
           </div>
            <div class="box">
              <div class="slink" >
                <div class="txt">邀请链接</div> 
                <div class="mylink">        
              <div class="nnr">{{codeWallet}}</div>
              <button
                type="button"
                class="fuzhi"
                v-clipboard:copy="codeWallet"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
            >
                <img src="../assets/img/fz.png" alt="" class="icon" />
            </button>
        </div>    
            </div>
              <div class="desc">
                通过二维码或邀请链接分享给你的伙伴
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
                <div class="slink">
                    <div class="txt">推荐人</div>
                    <div class="add">{{userInfo.parentAddress||'-/-'}}</div>
                </div>
            </div>
          </div>
      </div>
    
  
        <!-- 菜单 -->
      <van-popup
        v-model="menuShows"
        position="right"
        :style="{ height: '100%',background:'#1E133D'}"
      >
      <div class="sfelx" >
        <img src="../assets/img/gb.png" alt="" class="gb" @click="gbsmenu"/>
        <div class="mentop">       
          <img src="../assets/img/trx.png" alt="" class="logo" />
          <div class="address">{{userWallet|yingcang}}</div>
          <div class="level">
            <!-- <img src="../assets/img/lv1.png" alt="" class="ico" /> -->
            {{this.$store.getters.intID}}
          </div>        
        </div>
        <div class="menulist">
          <div class="menuitem fadeInLeft" @click="toPage('/')">首页</div>         
          <div class="menuitem act fadeInLeft" @click="gbsmenu">团队</div>
          <div class="menuitem fadeInLeft" @click="toPage('/Pool')">矿场</div>
          <div class="menuitem fadeInLeft" @click="toPage('/My')">我的</div>
        </div>      
        <div class="foot">
          <div class="link">
            <img src="../assets/img/ico1.png" alt="" class="limg"  @click="toUrl('https://t.me/vulcanusdt')">
            <!-- <img src="../assets/img/ico2.png" alt="" class="limg">
            <img src="../assets/img/ico3.png" alt="" class="limg"> -->
          </div>
          <div class="price">Copyright © 2024 项目名称. All rights reserved. </div>
        </div>
      </div>
      </van-popup>

      <van-popup
      v-model="tShows"
      position="center"
      :style="{ background: '#1E133D' }"
    >
      <div class="pbox">
        <img
          src="../assets/img/gb.png"
          alt=""
          class="gb"
          @click="tShows = false"
        />
        <div class="title">等级说明</div>
        <div class="sitem" v-for="(item,i) in list " :key="i">
          <div class="laber"> 
            <img v-if="item.userLevelId==1" src="../assets/img/lv1.png" alt="" class="ico"/>
            <img v-if="item.userLevelId==2" src="../assets/img/lv2.png" alt="" class="ico"/>
            <img v-if="item.userLevelId==3" src="../assets/img/lv3.png" alt="" class="ico"/>
            <img v-if="item.userLevelId==4" src="../assets/img/lv4.png" alt="" class="ico"/>
            <img v-if="item.userLevelId==5" src="../assets/img/lv5.png" alt="" class="ico"/>
            <img v-if="item.userLevelId==6" src="../assets/img/lv6.png" alt="" class="ico"/>
            <img v-if="item.userLevelId==7" src="../assets/img/lv7.png" alt="" class="ico"/>
      </div>
          <div class="txt">{{item.remark}}</div>
        </div>
        <div class="tips">
            注：7级成为全球U3合伙人，享受3%股权，每半年分红1次。
        </div>
      </div>
    </van-popup>
    </div>
  </template>
  
  <script>
  import { identity,toSign } from "@/api/Public";
  import vueQr from 'vue-qr'
  import Top from '@/components/top.vue'
  export default {
    components: {
            vueQr,Top
        },
    data() {
      return {
        userWallet:"",
        menuShows:false,
        tShows:false,
        codeWallet:"https://www.vulcanx.vip/index.html?code="+this.$store.getters.userWallet,
        ll:60,
        userInfo:'',
        
        list:[]
      }},
      beforeCreate() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          // 获取波场地址
          identity().then(
            (info) => {
              if (info.indexOf("T") == 0) {
                clearInterval(a);                   
                if(info==this.$store.getters.userWallet){return}
                this.$store.commit("userWallet", info);
                // this.yqmcode = this.getQueryString("code");
                this.userWallet=info
                this.getInfo()
              } else {
                this.$notify("请使用波场地址登录游戏");
              }
            },
            () => {
              if (i > 5) {
                this.shows = true;
                clearInterval(a);
              }
              this.$notify("钱包失联了！！");
            }
          );
        }
  
        i++;
      }, 1000);
    },
    filters:{
      yingcang(val) {
        if (val) {
          let a = val.replace(/(\w{5})\w+(\w{8})/, "$1...$2");
          return a;
        } else {
          return "连接钱包";
        }
      },
    },
    created(){
      this.userWallet = this.$store.getters.userWallet;
      this.getInfo()
    },
    methods:{
      ethConnect() {
      identity().then((info) => {
        if (info.indexOf("T") == 0) {             
          if(info==this.$store.getters.userWallet){return}
          toSign(info).then(res=>{
                if(res){
          this.$store.commit("userWallet", info);
          this.userWallet = info;
          this.getInfo(1)
                }})
        } 
      });
    },
      getInfo(isup){
        if(!this.userWallet){return}
        this.$post({
        url: "v1/bindAddress",
        data: {address :this.userWallet, isRefreshToken:isup?1:0,},
        callback: (res) => {
          this.$nextTick(function () {
            this.bdloading=false
            if (res.code==200) {   
              this.userInfo=res.data.member
              sessionStorage.setItem('token',res.data.token)
              this.codeWallet="https://www.vulcanx.vip/index.html?code="+this.userWallet
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });
      },
      toLevel(){
        this.$post({
        url: "v1/userLevelList",
        data: {},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.tShows=true
              this.list = res.data
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });
        
      },
      gbsmenu(){
        this.menuShows=false
      },
      onCopy() {
      if(this.$store.getters.userWallet){
      this.$notify({
                  message:"复制成功" ,
                  type: "success",
                });
      }
    },
    onError() {
      this.$notify("复制失败");
    },
    toUrl(url){
      window.open(url)
    },
      toPage(e){  this.$router.push({path:e})},
    }
  }
  
  </script>
  
  <style lang="scss" scoped>
  $t-mf: "PingFang";
  $t-f: "Alibaba";
    .main{
      background:  #000000   no-repeat;
      min-height: 100vh;
      background-size: 100% 100%;
      overflow-x: hidden;
    width: 750px;
    margin: 0 auto;
      .top{
        display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 34px 28px;
        .logo{
          width: 122px;
          height: 88px;
          .icosn{
            width: 100%;
            height: 100%;
          }
        }
        .right{
          display: flex;
      align-items: center;
        }
        .navbtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 140px;
        height: 72px;
        background: linear-gradient( 145deg, #2B406F 0%, #2A1D3D 38%, #4B0D67 100%);
        box-shadow: 0px -2 0px 0px #5D2E5A;
        border-radius: 36px;
        padding: 0 32px;
        .txicon {
          width: 44px;
          height: 44px;
        }
        .lang{
          font-size: 30px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 42px;
          margin-left: 20px;
        }
        }
        .menu{
          display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
    height: 72px;
    background: linear-gradient( 145deg, #2B406F 0%, #2A1D3D 38%, #4B0D67 100%);
    box-shadow: 0px -2 0px 0px #5D2E5A;
    border-radius: 22px;
    cursor: pointer;
    margin-left: 14px;
        .ico{
          width: 38px;
          height: 28px;
        }
        }
      }
      .content{
    
        .card{
            width: 692px;
            background: #1D1D1D;
border: 2px solid #737373;
border-radius: 20px;
  margin: 16px auto;
  position: relative;
  padding: 28px;
  box-sizing: border-box;
  
          .ctop{
            display: flex;
            align-items: center;
            justify-content: center;
            .title{
          font-size: 24px;
          font-family: $t-mf;
         color: #757575;
          margin-right: 8px;
            }
            .yw{
                width: 32px;
                height: 32px;
                cursor: pointer;
            }   
          }
          .level{
  font-family: $t-f;
  font-size: 46px;
  line-height: 62px;
  text-align: center;
  font-style: normal;
  background: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  
            }  
       .cont{
        margin: 40px 12px ;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item{

            .num{
                color: #FFFFFF;
                font-size: 46px;
                font-weight: bold;
            }
            .txt{
                font-size: 24px;
                color: #757575;
                margin-top: 12px;
            }
        }
       }
       .titles{
        font-size: 32px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: left;
       }
       .qur{
        margin: 36px auto;
       }
       .box{
        .slink{
            display: flex;
            align-items: center;
           
            .txt{
                font-size: 30px;
                color: #FFFFFF;
                margin-right: 14px;
                white-space: nowrap;
            }
            .add{
                color: #ffffff;
                font-size: 24px;
                font-weight: bold;
                overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
            }
            .mylink{
                background: rgba(255,255,255,0.1);
                border-radius: 30px;
                height: 60px;
                width: 480px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 22px ;
                box-sizing: border-box;
            .nnr{
                font-size: 22px;
                color: #FFFFFF;
                font-weight: bold;
                overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
            }
.fuzhi{
    background: transparent;
        border: none;
    .icon{
        width: 34px;
        height: 34px;
        cursor: pointer;
    }
}


            }
          
        }

        .desc{
    color: #757575;
    font-size: 24px;
    margin-top: 16px;
    text-align: left;
}
       }
            .tips{
              display: flex;
              align-items: center;
              .devs{
                color: #E9AB6C;
                font-size: 24px;
                font-weight: bold;
                font-family: $t-mf;
                position: relative;
                padding-left: 10px;
                margin-right: 16px;
              }
              .devs::before{
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateX(-50%);
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: #E9AB6C;
              }
            }
            .cont{
              margin-top: 32px;
              .topx{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .vv{
                  font-size: 24px;
                  color: #F6F6F6;                
                }
                .ll{
                  font-size: 24px;
                  color: #F6F6F6;
                  font-weight: bold;
                }
              }
              .line{
                background: #ffffff2e;
                width: 100%;
                height: 14px;
                border-radius: 7px;
                margin: 12px auto;
              }
              .act{
                background: rgba(255,255,255,0.4);
                height: 14px;
                border-radius: 7px;
              }
              .nnr{
                color: #FFFFFFFF;
                font-size: 24px;
                font-weight: bold;
                width: 100%;
                text-align: right;
                opacity: 0.5;
              }
            }
            .bottom{
              margin: 26px auto;
              .btn{
                background: #31704B;
                border-radius: 40px;
                width: 624px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                font-weight: bold;
                font-family: $t-mf;
                font-size: 32px;
                cursor: pointer;
              }
            }
        }
      }
      .ntop{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 34px 28px;
      .ntitle{
      background: #ffffff;
      font-size: 36px;
      font-family: $t-f;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;         
    }
    }
      .list{
        margin: 8px;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
          .item_card{
            min-width: 454px;
  background: linear-gradient( 180deg, #2E1E41 0%, #1B162C 100%);
  box-shadow: 0px -2 0px 0px #5D2E5A;
  border-radius: 20px;
  padding: 22px;
  box-sizing: border-box;
  margin: 24px;
  .title{
    font-size: 32px;
    color: #FFFFFF;
    font-weight: bold;
    font-family: $t-mf;
    text-align: left;
  }
  .desc{
    font-size: 24px;
    color: #826F94;
    font-family: $t-mf;  
    text-align: left;
    margin: 18px 0;
  }
  .linex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 0;
    .ll{
      font-size: 24px;
      font-weight: $t-mf;
      font-weight: bold;
      color: #9581BC;
    }
    .rr{
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: $t-mf;
      .num{
        color: #D113FF;
      }
      .dw{
        color: #FFFFFF;
      }
    }
  }
  .bottom{
    .btn{
      width: 312px;
  height: 80px;
  background: #7D2BE4;
  border-radius: 40px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  font-family: $t-mf;
  margin: 34px auto 0;
    }
  }
          }
        }
  
        .buttoms{
          font-size: 24px;
          color: #5D5879;
          padding: 24px ;
          .link{
            display: flex;
            align-items: center;
            justify-content: center;
            .ico{
              width: 50px;
              height: 50px;
              margin: 20px 29px;
            }
          }
        }
  
  
        .sfelx{
          position: relative;
          padding: 56px;
          box-sizing: border-box;
          .gb{
            position: absolute;
            top: 44px;
            right: 40px;
            width: 42px;
            height: 40px;
          }
          .mentop{
            display: flex;
            align-items: center;
            margin-top: 104px;
            .logo{
              width: 38px;
              height: 38px;
            }
            .address{
              font-size: 28px;
              color: #FFFFFF;
              font-weight: bold;
              margin-left: 8px;
            }
            .level{
              min-width: 80px;
            height: 36px;
            padding: 0 8px;
            margin-left: 10px;
            background: linear-gradient( 90deg, #B0B0B0 0%, #7B7B7B 100%);
            border-radius: 18px;
            border: 2px solid #FFFFFF;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 32px;
              
              .ico{
                width: 100%;
                height: 100%;
              }
            }
          }
          .menulist{
            margin: 44px 0;
            .menuitem{
              width: 432px;
              height: 64px;
              border-radius: 12px;
              color: #947AA6;
              font-size: 32px;
              font-family: $t-mf;
              display: flex;
              align-items: center;
              padding-left: 26px;
              margin: 26px 0;
              cursor: pointer;
            }
            .act{
              background: #7D2BE4;
              color: #FFFFFF;
            }
          }
          .foot{
            margin-top: 358px;
            .link{
              display: flex;
              align-items: center;
              .limg{
                width: 50px;
                height: 50px;
                margin-right: 56px;
              }
            }
            .price{
              margin: 22px 0;
              font-size: 22px;
              color: #5D5879;
  
            }
          }
        }


        .pbox {
    position: relative;
    width: 672px;
    min-height: 842px;
    background: #1D1D1D;
border: 2px solid #737373;
    border-radius: 20px;
    padding: 28px;
    box-sizing: border-box;
    .gb {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 40px;
      height: 40px;
    }
    .title {
      color: #ffffff;
      font-size: 32px;
      font-weight: bold;
      font-family: $t-mf;
      margin-top: 28px;
    }
    .sitem {
      width: 100%;
      margin: 48px 0;
      display: flex;
      align-items: flex-start;
      .laber {
        color: #ffffff;
        font-size: 26px;
        min-width: 80px;
        height: 36px;
        text-align: left;
        .ico{
            width: 100%;
            height: 100%;
        }
      }
      .txt{
        font-size: 26px;
        color: #ffffff;
        font-family: $t-mf;
        text-align: left;
        margin-left: 16px;
      }
    }
    .tips{
        font-size: 26px;
        color: #ffffff;
        text-align: left;
        margin-bottom: 44px;
    }
      
  }
    }
  </style>