<template>
  <div class="main">
    <Top/>
    <div class="ntop">
      <div class="ntitle">我的收益</div>
    </div>
    <div class="content">
      <div class="card">
        <div class="btntop" @click="toPage('/Details')">提现明细</div>
        <div class="ctop">
          <div class="title">累计收入(USDT)</div>
          <div class="num">{{userInfo.accumulatedIncome||0}}</div>
          <div class="u">≈${{userInfo.accumulatedIncome||0}}</div>
        </div>

        <div class="cont">
          <div class="item">
            <div class="num">{{userInfo.money||0}}</div>
            <div class="txt">可提现收益(USDT)</div>
          </div>
          <div class="item">
            <div class="num">{{userInfo.freezeMoney||0}}</div>
            <div class="txt">冻结收益(USDT)</div>
          </div>
        </div>
        <div class="bottom">
          <div class="btns" @click="toPage('/MyDetail')">明细</div>
          <div class="btn" @click="toTx()">提现</div>
        </div>
      </div>

      <div class="tips">
        <div class="tit">提示</div>
        <div class="txt">最小提现金额{{min||0}}USDT</div>
        <div class="txt">每次提现手续费{{free||0}}USDT</div>
      </div>
    </div>

    <!-- 菜单 -->
    <van-popup
      v-model="menuShows"
      position="right"
      :style="{ height: '100%', background: '#1E133D' }"
    >
      <div class="sfelx">
        <img src="../assets/img/gb.png" alt="" class="gb" @click="gbsmenu" />
        <div class="mentop">
          <img src="../assets/img/trx.png" alt="" class="logo" />
          <div class="address">{{ userWallet | yingcang }}</div>
          <div class="level">
            <!-- <img src="../assets/img/lv1.png" alt="" class="ico" /> -->
            {{this.$store.getters.intID}}
          </div>
        </div>
        <div class="menulist">
          <div class="menuitem fadeInLeft" @click="toPage('/')">首页</div>
          <div class="menuitem fadeInLeft" @click="toPage('/Team')">团队</div>
          <div class="menuitem fadeInLeft" @click="toPage('/Pool')">矿场</div>
          <div class="menuitem act fadeInLeft" @click="gbsmenu">我的</div>
        </div>
        <div class="foot">
          <div class="link">
            <img src="../assets/img/ico1.png" alt="" class="limg"  @click="toUrl('https://t.me/vulcanusdt')">
            <!-- <img src="../assets/img/ico2.png" alt="" class="limg" />
            <img src="../assets/img/ico3.png" alt="" class="limg" /> -->
          </div>
          <div class="price">
            Copyright © 2024 项目名称. All rights reserved.
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="txShows"
      position="center"
      :style="{ background: '#1E133D' }"
    >
      <div class="pbox">
        <img
          src="../assets/img/gb.png"
          alt=""
          class="gb"
          @click="txShows = false"
        />
        <div class="title">提现</div>
        <div class="sitem">
          <div class="laber">提现数量（USDT）</div>
          <input type="text" placeholder="0" class="input" v-model="txNum" @input="jstxNum"/>
          <div class="banner">可提现数量：{{userInfo.money}}</div>
        </div>
        <div class="sitem">
          <div class="laber">提现手续费(USDT)</div>
          <input type="text" placeholder="0" class="input" :value="free" readonly/>

        </div>
        <div class="sitem">
          <div class="laber">到账数量(USDT)</div>
          <input type="text" placeholder="0" class="input" :value="txNum-free>0?(txNum-free).toFixed(4):'0'" readonly/>
        </div>
        <div class="bottom">
          <van-button :loading="loading" type="primary" @click="toTxs" class="btn"
            >确认</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { identity,toSign } from "@/api/Public";
import vueQr from "vue-qr";
import Top from '@/components/top.vue'
export default {
  components: {
    vueQr,Top
  },
  data() {
    return {
      userWallet: "",
      menuShows: false,
      codeWallet:
        "https://xxx.xxxxxxxxxxxxx.com/a316516qweeeeeeeeeeeeeeeeeeeeeeee…",
      ll: 60,
      txShows: false,
      loading: false,
      userInfo:'',
      txNum:'',
      min:0,
      free:0
    };
  },
  beforeCreate() {
    var i = 0;
    var a = setInterval(() => {
      if (typeof window.ethereum !== "undefined") {
        // 获取波场地址
        identity().then(
          (info) => {
            if (info.indexOf("T") == 0) {
              clearInterval(a);                 
          if(info==this.$store.getters.userWallet){return}
              this.$store.commit("userWallet", info);
              // this.yqmcode = this.getQueryString("code");
              this.userWallet = info;
            } else {
              this.$notify("请使用波场地址登录游戏");
            }
          },
          () => {
            if (i > 5) {
              this.shows = true;
              clearInterval(a);
            }
            this.$notify("钱包失联了！！");
          }
        );
      }

      i++;
    }, 1000);
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{5})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "连接钱包";
      }
    },
  },
  created() {
    this.userWallet = this.$store.getters.userWallet;
    if(!this.userWallet){this.$router.push({ path: '/' });}
    this.tx()
    this.getInfo()
  },
  methods: {
    ethConnect() {
      identity().then((info) => {
        if (info.indexOf("T") == 0) {   
          if(info==this.$store.getters.userWallet){return}
          toSign(info).then(res=>{
                if(res){
          this.$store.commit("userWallet", info);
          this.userWallet = info;
          this.getInfo(1)
                }})
        } 
      });
    },
    jstxNum(){
      setTimeout(() => {
        this.txNum=this.userInfo.money-this.txNum>0?this.txNum:this.userInfo.money
      }, 100);
    },
    toTxs(){
      if(this.min-this.txNum>0){
        this.$notify('小于最小提现金额！')
        return
      }
      if(this.txNum-this.free>0){
      this.loading=true
      this.$post({
        url: "v1/withdraw",
        data: {cash_money  :this.txNum},
        callback: (res) => {
          this.$nextTick(function () {
            this.loading=false
            if (res.code==200) {   
              this.txShows=false
              this.getInfo()
              this.$notify({
                  message:"提现成功" ,
                  type: "success",
                });
            } else {
              this.$notify(res.msg)
            }
          });
        },
      });
    }
    },
    getInfo(isup){
      if(!this.userWallet){return}
        this.$post({
        url: "v1/bindAddress",
        data: {address :this.userWallet, isRefreshToken:isup?1:0,},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.code==200) {   
              this.userInfo=res.data.member
              sessionStorage.setItem('token',res.data.token)
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });
      },
    gbsmenu() {
      this.menuShows = false;
    },
    onCopy() {
      if (this.$store.getters.userWallet) {
        this.$toast("复制成功");
      }
    },
    onError() {
      this.$toast("复制失败");
    },
    toPage(e) {
      this.$router.push({ path: e });
    },
    toUrl(url){
      window.open(url)
    },
    toTx() {
      this.$get({
        url: "v1/getConfigDetail",
        data: {name:'transfer_fee,transfer_min'},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.txShows = true;
              this.free = res.data[0].value
              this.min = res.data[1].value
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });
    
    },
    tx() { this.$get({
        url: "v1/getConfigDetail",
        data: {name:'transfer_fee,transfer_min'},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.free = res.data[0].value
              this.min = res.data[1].value
            } else {
              this.$toast(res.msg)
            }
          });
        },
      });},
  },
};
</script>

<style lang="scss" scoped>
$t-mf: "PingFang";
$t-f: "Alibaba";
.main {
  background: #000000 no-repeat;
  min-height: 100vh;
  background-size: 100% 100%;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 28px;
    .logo {
      width: 122px;
      height: 88px;
      .icosn {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      display: flex;
      align-items: center;
    }
    .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 140px;
      height: 72px;
      background: linear-gradient(
        145deg,
        #2b406f 0%,
        #2a1d3d 38%,
        #4b0d67 100%
      );
      box-shadow: 0px -2 0px 0px #5d2e5a;
      border-radius: 36px;
      padding: 0 32px;
      .txicon {
        width: 44px;
        height: 44px;
      }
      .lang {
        font-size: 30px;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        margin-left: 20px;
      }
    }
    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      background: linear-gradient(
        145deg,
        #2b406f 0%,
        #2a1d3d 38%,
        #4b0d67 100%
      );
      box-shadow: 0px -2 0px 0px #5d2e5a;
      border-radius: 22px;
      cursor: pointer;
      margin-left: 14px;
      .ico {
        width: 38px;
        height: 28px;
      }
    }
  }
  .content {
    .card {
      width: 692px;
      background: #1C1C1C;
box-shadow: 0px -1px 0px 0px #1C1C1C;
border: 2px solid #737373;
      border-radius: 20px;
      margin: 16px auto;
      position: relative;
      padding: 38px;
      box-sizing: border-box;
      .btntop{
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 24px;
          font-family: $t-mf;
          color: #ffffff;
          margin-right: 8px;
      }
      .ctop {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 28px;
          font-family: $t-mf;
          color: #ffffff;
          margin-right: 8px;
        }
        .num {
          background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
          font-size: 50px;
          font-family: $t-f;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-top: 22px;
        }
        .u {
          font-size: 28px;
          color: #757575;
          font-weight: 500;
          font-family: $t-mf;
        }
      }

      .cont {
        margin: 40px 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          .num {
            color: #ffffff;
            font-size: 40px;
            font-weight: bold;
            line-height: 56px;
            justify-content: center;
            display: flex;
            align-items: flex-end;
          }
          .txt {
            font-size: 24px;
            color: #757575;
            margin-top: 12px;
          }
          .dw {
            font-size: 28px;
            line-height: 40px;
            color: #ffffff;
          }
        }
      }

      .titles {
        font-size: 32px;
        color: #ffffff;
        font-weight: 600;
        text-align: left;
      }

      .bottom {
        margin: 38px auto 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btns {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 40px;
          width: 280px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: bold;
          font-family: $t-mf;
          font-size: 32px;
          cursor: pointer;
        }
        .btn {
          background: #31704B;
          border-radius: 40px;
          width: 280px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: bold;
          font-family: $t-mf;
          font-size: 32px;
          cursor: pointer;
        }
      }
    }
  }
  .ntop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 34px 28px;
    .nbtn {
      width: 150px;
      height: 50px;
      background: #7d2be4;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 26px;
      font-weight: bold;
    }
    .ntitle {
      background: #ffffff;
      font-size: 36px;
      font-family: $t-f;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
    }
  }

  .tips {
    text-align: left;
    padding: 36px;
    box-sizing: border-box;
    .tit {
      font-size: 28px;
      color: #757575;
      font-weight: bold;
      margin-bottom: 12px;
    }
    .txt {
      font-size: 24px;
      color: #757575;
    }
  }

  .sfelx {
    position: relative;
    padding: 56px;
    box-sizing: border-box;
    .gb {
      position: absolute;
      top: 44px;
      right: 40px;
      width: 42px;
      height: 40px;
    }
    .mentop {
      display: flex;
      align-items: center;
      margin-top: 104px;
      .logo {
        width: 38px;
        height: 38px;
      }
      .address {
        font-size: 28px;
        color: #ffffff;
        font-weight: bold;
        margin-left: 8px;
      }
      .level {
        min-width: 80px;
            height: 36px;
            padding: 0 8px;
            margin-left: 10px;
            background: linear-gradient( 90deg, #B0B0B0 0%, #7B7B7B 100%);
            border-radius: 18px;
            border: 2px solid #FFFFFF;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 32px;
        .ico {
          width: 100%;
          height: 100%;
        }
      }
    }
    .menulist {
      margin: 44px 0;
      .menuitem {
        width: 432px;
        height: 64px;
        border-radius: 12px;
        color: #947aa6;
        font-size: 32px;
        font-family: $t-mf;
        display: flex;
        align-items: center;
        padding-left: 26px;
        margin: 26px 0;
        cursor: pointer;
      }
      .act {
        background: #7d2be4;
        color: #ffffff;
      }
    }
    .foot {
      margin-top: 358px;
      .link {
        display: flex;
        align-items: center;
        .limg {
          width: 50px;
          height: 50px;
          margin-right: 56px;
        }
      }
      .price {
        margin: 22px 0;
        font-size: 22px;
        color: #5d5879;
      }
    }
  }

  .pbox {
    position: relative;
    width: 672px;
    min-height: 842px;
    background: #1D1D1D;
border: 2px solid #737373;
    border-radius: 20px;
    padding: 28px;
    box-sizing: border-box;
    .gb {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 40px;
      height: 40px;
    }
    .title {
      color: #ffffff;
      font-size: 32px;
      font-weight: bold;
      font-family: $t-mf;
      margin-top: 28px;
    }
    .sitem {
      width: 100%;
      margin: 48px 0;
      .laber {
        color: #ffffff;
        font-size: 26px;
        width: 100%;
        text-align: left;
        margin: 14px 0;
      }
      .banner {
        color: #757575;
        font-size: 26px;
        font-family: $t-mf;
        text-align: right;
        margin-top: 14px;
      }
      .input {
        width: 614px;
        height: 80px;
        background: rgba(245, 245, 245, 0.1);
        border-radius: 6px;
        border: none;
        padding: 0 32px;
        color: #b0b0b0;
        font-size: 28px;
        box-sizing: border-box;
      }
    }
    .bottom {
      margin: 60px auto;

      .btn {
        border: none;
        width: 600px;
        height: 80px;
        background: #31704B;
        color: #ffffff;
        font-size: 28px;
        font-weight: bold;
        border-radius: 40px;
      }
    }
  }
}
</style>
